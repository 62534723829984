<template>
  <div class="layout-footer">
    <!-- <img alt="Logo" :src="footerImage()" height="20" class="mr-2" /> -->
    <!-- by -->
    <app-versao />
  </div>
</template>

<script>
import AppVersao from '@/components/shared/versao/AppVersao'

export default {
  name: 'AppFooter',
  components: {
    'app-versao': AppVersao,
  },
  methods: {
    // footerImage() {
    // 	return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
    // }
  },
}
</script>
